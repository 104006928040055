/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import Cookies from 'js-cookie';
import { GrowthBook, GrowthBookProvider as OriginalGrowthBookProvider } from '@growthbook/growthbook-react';
import { PropsWithChildren, useEffect, useMemo } from 'react';

const GB_UUID_COOKIE = 'gb_id';

const GrowthBookProvider = (props: PropsWithChildren) => {
    const onExperimentView = (experiment: any, result: any) => {
        console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.key,
        });
    };
    const gb = useMemo(
        () =>
            new GrowthBook({
                apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
                clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
                decryptionKey: undefined,
                trackingCallback: onExperimentView,
            }),
        [],
    );

    useEffect(() => {
        gb.init({
            streaming: true,
        });

        let uuid = Cookies.get(GB_UUID_COOKIE);

        if (!uuid) {
            uuid = Math.random().toString(36).substring(2);
            Cookies.set(GB_UUID_COOKIE, uuid);
        }
        gb.setAttributes({
            id: uuid,
        });
    }, [gb]);

    return <OriginalGrowthBookProvider growthbook={gb}>{props.children}</OriginalGrowthBookProvider>;
};

export default GrowthBookProvider;
