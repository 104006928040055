/* eslint-disable react/jsx-no-useless-fragment */
import useQuizId from 'hooks/useQuizId';

const Splitter = () => {
    const { quizId } = useQuizId({ splitOnly: true });

    // eslint-disable-next-line react/react-in-jsx-scope
    return <></>;
};

export default Splitter;
