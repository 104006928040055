import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ConversionProvider from 'providers/ConversionProvider';
import GrowthBookProvider from 'providers/GrowthBookProvider';
import Splitter from 'components/Splitter';
import TikTokProvider from 'providers/TikTokProvider';

const Switcher = lazy(() => import('components/Swicher'));
const ThankPage = lazy(() => import('components/ThankPage'));

const App = () => (
    <div className="App">
        <BrowserRouter>
            <GrowthBookProvider>
                <ConversionProvider>
                    <TikTokProvider>
                        <Routes>
                            <Route path="quiz/:quizId" element={<Switcher />} />
                            <Route path="thank-page/:quizId" element={<ThankPage />} />
                            <Route path="split" element={<Splitter />} />
                        </Routes>
                    </TikTokProvider>
                </ConversionProvider>
            </GrowthBookProvider>
        </BrowserRouter>
    </div>
);

export default App;
