import { PropsWithChildren, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';

const TikTokProvider = (props: PropsWithChildren) => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let ttclid = Cookies.get('ttclid');

        if (!ttclid) {
            // @ts-ignore
            ttclid = searchParams.get('ttclid');

            if (!ttclid) {
                return;
            }

            Cookies.set('ttclid', ttclid);
        }
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment, react/destructuring-assignment, react/react-in-jsx-scope
    return <>{props.children}</>;
};

export default TikTokProvider;
