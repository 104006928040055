import { useFeatureValue } from '@growthbook/growthbook-react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

const allowedQuizes = ['split'];

const useQuizId = (options?: { splitOnly: boolean }) => {
    let { quizId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const funnelRedirectURL = useFeatureValue('funnel-redirect-url', 'NOR');

    const splitOnly = options?.splitOnly || false;

    if ((splitOnly || allowedQuizes.includes(quizId as string)) && funnelRedirectURL && funnelRedirectURL !== 'NOR') {
        const urlPattern = /quiz\/(\d+)/;
        const match = funnelRedirectURL.match(urlPattern);

        if (match) {
            // eslint-disable-next-line prefer-destructuring
            quizId = match[1];

            const url = new URL(`/quiz/${quizId}`, window.location.origin);

            searchParams.forEach((value, key) => {
                url.searchParams.set(key, value);
            });
            navigate(`${url.pathname}${url.search}`, { replace: true });
        }
    }

    return { quizId };
};

export default useQuizId;
